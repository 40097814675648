import { useTranslation } from "lib/i18n/translation"
import { FETCH_SELECTIONS } from "lib/graphql/queries"
import { listNames } from "lib/tools/categories"
import { Slider } from "components/common"
import { Query } from "components/common"
import Image from "components/common/image"
import SkeletonLink from "components/common/skeleton-link"
import MonthListQuery from "components/common/month-list"

import image, { DIRECTOR_AVATAR, DIRECTOR_TOP, CATEGORY_BANNER_BIG, CATEGORY_SELECTION } from "lacinetek-common/src/images"

const NewsHeader = () => {
	const { c } = useTranslation("common")

	return (
		<>
			<section>
				<h1 className="main-title">{c("news")}</h1>
				<p className="introduction">{c("this-month-on-lacinetek-strong")}</p>
			</section>
			<section className="focus has-grid">
				<ul className="grid news">
					<MonthListQuery
						renderLoading={() => (
							<>
								<ListOfMonth loading={true} />
								<Focuses loading={true} />
							</>
						)}
					>
						{monthList => {
							return (
								<>
									{!!monthList && <ListOfMonth list={monthList} director={monthList.director} />}
									<Focuses />
								</>
							)
						}}
					</MonthListQuery>
				</ul>
			</section>
		</>
	)
}

const ListOfMonth = ({ list, director, loading }) => {
	const { c } = useTranslation("common")

	return (
		<li className="item month-list">
			<SkeletonLink loading={!list} href="/lists/[id]" as={`/lists/${list ? list.linkRewrite : ""}`}>
				<a title={c("see-selection")} className="item-content">
					<figure className={loading ? "skeleton" : ""}>
						<picture>
							<Image
								src={(list && director) ? image(director, DIRECTOR_AVATAR, DIRECTOR_TOP) : "/static/images/default_director.png"}
								alt={list ? list.name.replace(" | ", " ") : ""}
								style={{ aspectRatio: "1/1" }}
							/>
						</picture>
					</figure>
					<div className={`infos ${loading ? "skeleton" : ""}`}>
						<h2><strong>{(list && list.female) ? c("director-female") : c("director")}</strong> {c("of-the-month")}</h2>
						<p className="title-alt">
							{(list && director) ? listNames(director.name)[0] : "Maren"} <b>{list ? listNames(director.name)[1] : "Ade"} {list && listNames(director.name)[2] ? listNames(director.name)[2] : ""}</b>
						</p>
					</div>
				</a>
			</SkeletonLink>
		</li>
	)
}

const baseSelections = [
	{ name: "base-selection-1", banner: "/static/images/default_selection.jpg" },
	{ name: "base-selection-2", banner: "/static/images/default_selection.jpg" },
	{ name: "base-selection-3", banner: "/static/images/default_selection.jpg" },
]

const Focuses = ({ loading }) => {
	if (loading) {
		return <FocusesContent selections={baseSelections} loading={true} />
	}

	return (
		<li className="item large svod-list">
			<div className="item-content">
				<Query
					query={FETCH_SELECTIONS}
					renderLoading={() => (
						<FocusesContent selections={baseSelections} loading={true} />
					)}
				>
					{({ cms: { carouselWidget: { slides: { items } } } }) => {

						return (
							<FocusesContent key="selections-list" selections={items.map(i => i.target).filter(s => !!s)} />
						)
					}}
				</Query>
			</div>
		</li>
	)
}

const Slide = ({ loading, index, ...selection }) => {
	const { c } = useTranslation("common")

	return (
		<SkeletonLink
			href="/selection/[id]"
			as={`/selection/${selection.linkRewrite}`}
			loading={loading}
			className="slide-content"
		>
			<a
				title={c("see-selection")}
				data-overlay="#overlay-discover"
				className={`slide-content${loading ? " skeleton" : ""}`}
			>
				<div className={`infos${loading ? " skeleton" : ""}`}>
					<h2>{c('focus-single')}</h2>
					<span className="title-alt large">
						{c(selection.name)}
					</span>
				</div>
				<figure className="background">
					<picture>
						<Image src={image(selection, CATEGORY_SELECTION, CATEGORY_BANNER_BIG)} alt={c(selection.name)} />
					</picture>
				</figure>
			</a>
		</SkeletonLink>
	)
}


const FocusesContent = ({ selections, loading }) => (
	<Slider
		slides={selections}
		slideComponent={Slide}
		loading={loading}
		navClassName="min alt"
		asList={false}
		hidePagination
	/>
)

export default NewsHeader
