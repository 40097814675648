import classNames from "classnames"
import image, { PRODUCT_PHOTOGRAMME } from "lacinetek-common/src/images"

import { useTranslation } from "lib/i18n/translation"
import { useVideoPlayer } from "lib/video-player"

import SkeletonLink from "components/common/skeleton-link"
import ProductAccessLink from "components/common/product-access-link"
import Image from "components/common/image"

export default ({ product, loading, tiny, forceAvailable }) => {
	const { c } = useTranslation("common")
	const { seeFilm } = useVideoPlayer()

	const showInCatalog = () => {
		return !product?.hideInCatalog || product.hideInCatalog !== true
	}

	return (
		<li
			className={classNames("item", { "unavailable": !(product.available || forceAvailable) })}
			key={`product-${product.id}`}
		>
			<div className="item-content">
				{
					showInCatalog()
						?
						<SkeletonLink
							href="/film/[id]"
							as={`/film/${product.linkRewrite}`}
							key={`film-link-${product.id}`}
							loading={loading}
						>
							<a
								title={c("see-single-film")}
								className="more-link"
							>
								<span className="label">
									{c("see-single-film")}
								</span>
							</a>
						</SkeletonLink>
						: <a
							href="#"
							onClick={e => { e.preventDefault(); seeFilm(product, true) }}
							rel="nofollow noopener"
							title={c("watch-the-movie")}
							data-overlay="#overlay-player"
							className="more-link"
						></a>
				}
				<div className="film-link">
					<figure className={`cover ${loading ? "skeleton" : ""}`} style={{ aspectRatio: "588/353" }}>
						<picture>
							<Image
								src={loading ? "/static/images/default_movie.png" : image(product, PRODUCT_PHOTOGRAMME)}
								alt={product.name}
							/>
						</picture>
					</figure>
					<div className="infos">
						<h3 className={`title large ${loading ? "skeleton" : ""}`}>
							{product.name}
						</h3>
						{showInCatalog() && (
							<>
								<span className={`director ${loading ? "skeleton" : ""}`}> {c("by")} {product.director}</span>
								<span className={`year ${loading ? "skeleton" : ""}`}>{product.year}</span>
								<span className={`country ${loading ? "skeleton" : ""}`}>{product.origin}</span>
								<span className={`duration ${loading ? "skeleton" : ""}`}>{product.duration}</span>
								{
									(!tiny && product.bonusesCount > 0)
									&& <ul className={`has-bonus ${loading ? "skeleton" : ""}`}>
										<li className="exclusive">
											{c("bonus")}
										</li>
									</ul>
								}
							</>
						)}
					</div>
				</div>
			</div>
			{
				showInCatalog() &&
				<nav className={`film-actions ${loading ? "skeleton" : ""}`} aria-label={c("film-actions")}>
					{
						!loading
						&& <ProductAccessLink
							product={product}
							forceAvailable={forceAvailable}
							canDownload
						/>
					}
				</nav>
			}
		</li>
	)
}
