import { useEffect, useState } from "react"
import {
	metadata,
	SVOD_END_DATE,
	TEXT_BUTTON,
	SVOD2_HAS_NOT_SUBSCRIBED,
	SVOD2_LINK_BUTTON
} from "lacinetek-common/src/metadata"

import { useTranslation } from "lib/i18n/translation"
import { sanitizeUrl } from "lib/redirects"
import { useLanguages } from "lib/languages"
import { useAccesses } from "lib/accesses"
import { carouselClicSubscriptionDataLayer } from "lib/gtm/interactions"

import SkeletonLink from "components/common/skeleton-link"
import Image from "components/common/image"
import { Slider } from "components/common"
import { getBestImage } from "./block-header"

export const HeaderSubscription = ({ block, gtmAction = false }) => (
	<section className="focus full has-slider no-fx">
		<HeaderSubscriptionSlider block={block} className="full" gtmAction={gtmAction} />
	</section>
)

export const HeaderSubscriptionSlider = ({ block, className, gtmAction }) => {
	const { hasSubscribed } = useAccesses()
	const [slides, setSlides] = useState(block.children.items)

	useEffect(
		() => {
			setSlides(block.children.items.filter(slide => (
				metadata(slide, SVOD2_HAS_NOT_SUBSCRIBED) !== "Oui" || !hasSubscribed
			)))
		},
		[hasSubscribed]
	)

	return (
		<Slider
			className={className}
			navClassName="min alt"
			slides={slides}
			slideComponent={Component}
			slidePropKey="header"
			asList={false}
			keyExtractor={item => item.id}
			gtmAction={gtmAction}
		/>
	)
}

const Component = ({ loading, index, gtmAction, ...item }) => {
	const { c } = useTranslation("common")
	const endDate = metadata(item.header, SVOD_END_DATE)

	return (
		<div className="slide-content" role="group" aria-label="2 / 4">
			<figure className="cover" style={{ aspectRatio: "1600/575" }}>
				<picture>
					<Image
						src={getBestImage(item.header)}
						alt={item.header.name}
						className="cover"
					/>
				</picture>
			</figure>
			<div className="infos fixed">
				<div className="svod-infos">
					<span className="type">{item.header.descriptionShort}</span>
					<span className="title" dangerouslySetInnerHTML={{ __html: item.header.description }} />
					{
						endDate
						&& <div className="alert short large icon-clock">{`${c("until")} ${endDate}`}</div>
					}
					<ButtonLink slide={item.header} className={"button only-desktop"} gtmAction={gtmAction} />
				</div>
			</div>
			<a
				href="#section-selection-du-mois"
				title="Sélection du Mois"
				className="more-link only-mobile"
			>
				<span className="label">{metadata(item.header, TEXT_BUTTON)}</span>
			</a>
		</div>
	)
}

const ButtonLink = ({ slide, className, gtmAction }) => {
	const [target, setTarget] = useState("#")
	const [ancre, setAncre] = useState(true)
	const { current } = useLanguages()

	useEffect(() => {
		if (metadata(slide, SVOD2_LINK_BUTTON) && isNaN(metadata(slide, SVOD2_LINK_BUTTON))) {
			setTarget(sanitizeUrl(metadata(slide, SVOD2_LINK_BUTTON)))
			setAncre(false)
		}
	}, [])

	const goToSection = (event, gtmAction) => {
		if (gtmAction) {
			carouselClicSubscriptionDataLayer("Button")
		}
		if (!ancre) {
			return null
		}

		event.preventDefault()
		const section = document.getElementById(metadata(slide, SVOD2_LINK_BUTTON))
		const header = document.getElementsByTagName("HEADER")[0]

		window.scrollTo({
			top: section.offsetTop - header.clientHeight,
			left: 0,
			behavior: "smooth",
		})
	}

	return (
		<SkeletonLink href={target}>
			<a
				title={metadata(slide, TEXT_BUTTON)}
				className={className}
				onClick={(event) => goToSection(event, gtmAction)}
			>
				{metadata(slide, TEXT_BUTTON)}
			</a>
		</SkeletonLink>
	)
}
