import { SVOD_IMAGE_TITLE, metadata } from "lacinetek-common/src/metadata"
import ReactMarkdown from "react-markdown"

export const BlockImageTitle = ({ block, inOverlay }) => {
	let title = ""
	const iconClassMatch = block.descriptionShort.match(/\[(.+)\]/)
	let Tag = inOverlay ? "h3" : "h2"

	if (iconClassMatch) {
		title = block.descriptionShort.replace(iconClassMatch[0], "")
	} else {
		title = block.descriptionShort
	}

	return (
		<>
			{
				inOverlay
				&& <h2 className="title-alt large" >
					<ReactMarkdown
						allowedElements={["strong"]}
						unwrapDisallowed={true}
					>
						{title}
					</ReactMarkdown>
				</h2>
			}
			<Tag className="title-alt large" dangerouslySetInnerHTML={{ __html: metadata(block, SVOD_IMAGE_TITLE) }} />
		</>
	)

}
