import { useQuery } from "@apollo/react-hooks"
import { metadata, SVOD_BLOCK_TYPE, SVOD_TYPE_HEADER } from "lacinetek-common/src/metadata"

import { FETCH_SVOD_CATEGORIES } from "lib/graphql/queries"
import { useTranslation } from "lib/i18n/translation"
import { useCountry } from "lib/country"
import { useAccesses } from "lib/accesses"
import { subscribeClicSubscriptionDataLayer } from "lib/gtm/interactions"

import SkeletonLink from "components/common/skeleton-link"
import { HeaderSubscriptionSlider } from "components/subscription/header"

const Svod = ({ svodAvailable }) => {
	if (!svodAvailable) {
		return null
	}
	const { c } = useTranslation("common")
	const { hasSubscribed } = useAccesses()
	const { country: { isoCode } } = useCountry()

	// TODO: need to cache this query result to use everywhere
	const { data, loading } = useQuery(FETCH_SVOD_CATEGORIES, {
		variables: {
			query: `abonnement-${isoCode}`
		}
	})

	if (hasSubscribed || loading) {
		return null
	}

	const { children: { items: blocks } } = data.cms.categories.items[0]

	const block = blocks.find(block => metadata(block, SVOD_BLOCK_TYPE) === SVOD_TYPE_HEADER)

	if (!block) {
		return null
	}

	return (
		<section className="svod-color">
			<div className="section-title inline">
				<h2 className="icon-selection-alt">
					{c("news-in-subscription-strong")}
				</h2>
				<p>
					<SkeletonLink href="/subscription">
						<a
							onClick={ subscribeClicSubscriptionDataLayer }
							title={c("subscribe")}
							className="button small"
						>
							{c("subscribe")}
						</a>
					</SkeletonLink>
				</p>
			</div>
			<HeaderSubscriptionSlider block={block} gtmAction={true} />
		</section>
	)
}

export default Svod
