import classNames from "classnames"
import image, { CATEGORY_BANNER_BIG, CATEGORY_SELECTION } from "lacinetek-common/src/images"
import { metadata, SVOD_END_DATE } from "lacinetek-common/src/metadata"

import { useTranslation } from "lib/i18n/translation"
import { useVideoPlayer } from "lib/video-player"

import Image from "components/common/image"
import { BlockImageTitle } from "./block-image-title"

export const TeaserLink = ({ block, mobile }) => {
	const { c } = useTranslation()
	const { seeCategoryTeaser } = useVideoPlayer()

	const handleWatchTeaser = (event) => {
		event.preventDefault()
		seeCategoryTeaser(block)
	}

	if (block.videos.items.length === 0) {
		return null
	}

	return (
		<a
			href="#"
			rel="nofollow noopener"
			title={c("watch-teaser")}
			className={classNames({
				"icon-play button small only-mobile": mobile,
				"video-link more-link teasing only-desktop": !mobile
			})}
			onClick={handleWatchTeaser}
		>
			{mobile
				? c("watch-teaser")
				: <span className="label">{c("watch-teaser")}</span>
			}
		</a>
	)
}

export const getBestImage = (block) => {
	if (block.images.find(image => image.type === CATEGORY_SELECTION)) {
		return image(block, CATEGORY_SELECTION)
	}

	return image(block, CATEGORY_BANNER_BIG)
}

export const BlockHeader = ({ block, inOverlay }) => {
	const { c } = useTranslation()

	const endDate = metadata(block, SVOD_END_DATE)

	return (
		<section className="focus svod">
			<TeaserLink block={block} mobile />
			<TeaserLink block={block} />
			<div className="infos">
				<BlockImageTitle block={block} inOverlay={inOverlay} />
				{endDate && (
					<span className="alert short large icon-clock">
						{`${c("until")} ${endDate}`}
					</span>
				)}
			</div>
			<figure className="background">
				<picture>
					<Image
						src={getBestImage(block)}
						alt={block.name}
					/>
				</picture>
			</figure>
		</section>
	)
}
