import { Bonuses } from "components/common"
import AcquisitionQuery from "components/common/acquisitions"
import BonusesQuery from "components/common/bonuses-query"

import baseBonus from "components/common/skeleton/base-bonus"
const baseBonuses = [baseBonus, baseBonus, baseBonus, baseBonus]

import { metadata, FEATURE_BONUS_TYPE } from "lacinetek-common/src/metadata"
import { BONUS_ARCHIVES, BONUS_EXCLUSIFS } from "lib/tools/product"

const NewsBonuses = ({}) => (
	<AcquisitionQuery
		renderLoading={() => (
			<>
				<Bonuses type={BONUS_EXCLUSIFS} bonuses={baseBonuses} loading={true} />
				<Bonuses type={BONUS_ARCHIVES} bonuses={baseBonuses} loading={true} />
			</>
		)}
	>
		{acquisitions => {

			if (acquisitions.products.items.length == 0) return null

			return (
				<BonusesQuery
					productIds={acquisitions.products.items.map(a => a.id)}
					renderLoading={() => (
						<>
							<Bonuses type={BONUS_EXCLUSIFS} bonuses={baseBonuses} loading={true} />
							<Bonuses type={BONUS_ARCHIVES} bonuses={baseBonuses} loading={true} />
						</>
					)}
				>
					{bonuses => (
						<>
							<Bonuses key={`news-bonuses-exclusifs`} type={BONUS_EXCLUSIFS} bonuses={bonuses.filter(b => metadata(b, FEATURE_BONUS_TYPE) == BONUS_EXCLUSIFS) } />
							<Bonuses key={`news-bonuses-archives`} type={BONUS_ARCHIVES} bonuses={bonuses.filter(b => metadata(b, FEATURE_BONUS_TYPE) == BONUS_ARCHIVES) } />
						</>
					)}
				</BonusesQuery>
			)
		}}
	</AcquisitionQuery>
)

export default NewsBonuses
