import { useState } from "react"
import AcquisitionQuery from "components/common/acquisitions"
import { baseProduct } from "components/common/skeleton"
import SkeletonLink from "components/common/skeleton-link"
import Product from "components/common/product-thumb"
import { useTranslation } from "lib/i18n/translation"
import Products from "components/common/products"
import { useCountry } from "lib/country"

const Acquisitions = ({ innerRef }) => {
	const { c } = useTranslation("common")
	const baseAcquisitions = { products: { items: [baseProduct, baseProduct, baseProduct, baseProduct] } }
	const { country } = useCountry()
	const [exists, setExists] = useState(null)

	if (exists === false) {
		return null
	}

	return (
		<section ref={innerRef}>
			<h2 className="section-title icon-reel">{c("newly-available-films-strong")}</h2>
			<div className="slider films">
				<div className="slider-container">
					<AcquisitionQuery
						renderLoading={() => <List acquisitions={baseAcquisitions} loading={true} />}
					>
						{acquisitions => {
							if (exists === null) {
								setExists(acquisitions.products.items.length > 0)
							}
							return (
								<List key="news-acquisitions-list" acquisitions={acquisitions} />
							)
						}}
					</AcquisitionQuery>
				</div>
			</div>
			{ country.previous_acquisitions && (
					<p className="centered">
						<SkeletonLink href="/acquisitions">
							<a
								title={c("see-acquisitions-previous-months")}
								className="icon-reel button"
							>
								{c("see-acquisitions-previous-months")}
							</a>
						</SkeletonLink>
					</p>
			)}
		</section>
	)
}

const List = ({ acquisitions, loading }) => (
	<Products products={acquisitions.products.items} loading={loading}>
		{(products, pLoading) => {
			return (
				<ul className="grid films">
					{ products.map((acquisition, index) => (
						<Product
							key={`acquisition-${index}`}
							product={acquisition}
							loading={loading}
							forceAvailable={true}
						/>
					))}
				</ul>
			)
		}}
	</Products>
)

export default Acquisitions
