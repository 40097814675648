import { useEffect, useRef } from "react"
import ReactDOM from "react-dom"
import { useRouter } from "next/router"

import { BLOG_CLASSICS, BLOG_CINETEK } from "lib/tools/blogs"
import Gtm from "lib/gtm"
import { useTranslation } from "lib/i18n/translation"
import { useCountry } from "lib/country"

import { Header, Acquisitions, Bonuses, Svod } from "components/news"
import { Articles } from "components/common"
import { Selections } from "components/home"

const News = () => {
	const acquisitionsRef = useRef(null)

	const classics = { id: process.env.BLOG_CATEGORIES.find(c => c.key == BLOG_CLASSICS).id, icon: "pen" }
	const cinetek = { id: process.env.BLOG_CATEGORIES.find(c => c.key == BLOG_CINETEK).id, icon: "file" }

	const router = useRouter()
	const url = router ? router.asPath : null
	const { c } = useTranslation("common")
	const { svodAvailable } = useCountry()

	useEffect(() => {
		if (window.location.hash == "#acquisitions" && acquisitionsRef.current) {
			const block = ReactDOM.findDOMNode(acquisitionsRef.current)
			setTimeout(() => {
				window.scrollTo({
					left: 0,
					top: block.offsetTop - 130,
					behavior: "smooth"
				})
			}, 200)
		}
	}, [acquisitionsRef])

	return (
		<>
			<Gtm pageType="Page Nouveautés" pagePath={url} pageTitle={c("news")} />
			<Header />
			<Acquisitions innerRef={acquisitionsRef} />
			<Articles key="articles-classics" categoryId={classics.id} icon={classics.icon} hasExtras={true} />
			<Bonuses />
			<Selections hideDesc={true} />
			<Articles key="articles-cinetek" categoryId={cinetek.id} icon={cinetek.icon} showAllButton={true} />
			<Svod svodAvailable={svodAvailable} />
		</>
	)
}

export default News
